.eky-ofl-font{
  font-family: 'Montserrat-normal';
}

.eky-ofl-title-font{
  font-family: 'Montserrat-semi-bold';
}

@font-face {
  font-family: 'Montserrat-normal';
  src: url('../../fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-semi-bold';
  src: url('../../fonts/Montserrat-SemiBold.ttf') format('truetype');
}